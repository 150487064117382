import striptags from 'striptags';
import _head from 'lodash/head';
import _tail from 'lodash/tail';
import _split from 'lodash/split';
import _join from 'lodash/join';
import _dateFormat from 'date-fns/format';

const he = require('he');

export const cleanExcerpt = postContent => {
  const cleanContent = striptags(postContent);
  const excerpt = _join(_split(cleanContent, ' ', 100), ' ');
  const finalExcerpt = he.decode(excerpt);
  return `${finalExcerpt} ...`;
};

export const cleanTitle = title => {
  return he.decode(title);
};

export const getFeaturedImage = postContent => {
  let img = null;
  const imageRegex = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;
  const result = imageRegex.exec(postContent);
  if (result) img = _head(_tail(result));
  return img;
};

export const formatDate = dateString => {
  return _dateFormat(dateString, 'MMMM D, YYYY');
};

const allowedTags = [
  'a',
  'p',
  'li',
  'ul',
  'ol',
  'strong',
  'b',
  'i',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'img',
];

export const cleanContent = rawPostContent => {
  const strippedStepOne = striptags(rawPostContent, allowedTags);
  const step2 = strippedStepOne.replace(/<p>(&nbsp;*?)<\/p>/gi, '');
  return step2.replace(/<p><\/p>/gi, '');
};
